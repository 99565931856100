import React from 'react'
 
const PageBannerCaseStudy = (props) => {
  
 
    return (
        <div className="page-banner">
            <img src={props.data.publicURL} width="100%" ></img>       
        </div>
    )
}

export default PageBannerCaseStudy
