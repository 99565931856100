import React from 'react'
import { Carousel } from 'react-bootstrap'
import SectionHeader from '../../components/sectionHeader'
import quoteIcon from '../../assets/icons/icon-quote.png'

import './testimonials.scss'

const Testimonials = () => {
    return (
        <div className="testimonials">
            <SectionHeader 
                icon={quoteIcon}
            />

            <Carousel>     
                <Carousel.Item>
                    <h3 className="highlight">“We saved 2,000 hours of effort a year by automating our fee management with Feoda”.</h3>                                
                    <p>NSW College Business Manager</p>
                </Carousel.Item>

                <Carousel.Item>                                
                    <h3 className="highlight">“Feoda’s very user-friendly. After an hour of training, I was good to go.”</h3>  
                    <p>NSW College Bursar</p>                                
                </Carousel.Item>            
                
                <Carousel.Item>
                    <h3 className="highlight">“…seamless administration, from when the student first enters the school to the day they graduate.”</h3>                                
                    <p>NSW College Business Manager</p>
                </Carousel.Item>

                <Carousel.Item>
                    <h3 className="highlight">“School fees, fixed assets, purchase orders—it’s all there.”</h3>  
                    <p>NSW College Bursar</p>                              
                </Carousel.Item>

                <Carousel.Item>                                
                    <h3 className="highlight">"Adding Feoda to our existing environment reduced our finance and admin workload dramatically."</h3>
                    <p>NSW College Business Manager</p>                                
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default Testimonials
