import React from 'react'
import marker1 from '../../assets/marker1.svg'
import marker2 from '../../assets/marker2.svg'
import marker3 from '../../assets/marker3.svg'
import marker4 from '../../assets/marker4.svg'
import graph from '../../assets/graph.svg'

import './takeOuts.scss'


const TakeOuts = () => {
    return (
        <section className="case-study__takeouts">
            <h4 className="highlight">Key take outs..</h4>      
            <div className="markers">
                <figure>
                    <div>
                        <img src={marker1} alt="marker"></img>
                        <span>1.</span>
                    </div>
                    <figcaption>2,000hrs yearly effort saved from day 1</figcaption>
                </figure>

                <figure>
                    <div>
                        <img src={marker2} alt="marker"></img>
                        <span>2.</span>
                    </div>
                    <figcaption>Automatic audit ready compliance</figcaption>
                </figure>

                <figure>
                    <div>
                        <img src={marker3} alt="marker"></img>
                        <span>3.</span>
                    </div>
                    <figcaption>20% of tuitions paid within 5hrs</figcaption>
                </figure>

                <figure>
                    <div>
                        <img src={marker4} alt="marker"></img>
                        <span>4.</span>
                    </div>
                    <figcaption>1hr training requirement</figcaption>
                </figure>
            </div>

            <figure className="graph">
                <img src={graph} alt="graph" width="100%"></img>            
            </figure>      
        </section>
    )
}

export default TakeOuts
