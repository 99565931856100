import React from 'react'
import enrolTimeIcon from '../../assets/icons/icon-enrol-time.png'
import enrolIcon from '../../assets/icons/icon-enrol-green.png'
import planIcon from '../../assets/icons/icon-plan-green.png'

import './results.scss'

const Results = () => {
    return (
        <section className="case-study__results">
            <div className="heading">
                <h3 className="highlight">Results.</h3>
                <img src={enrolTimeIcon} alt="icon" ></img>
                <p><strong>The school achieved a transformative outcome, most notably</strong></p>
                <p className="highlight"><strong>saving 2,000 hours of administrative effort per year </strong></p>
                <p><strong>from the implementation of fee management automation alone. </strong></p>
            </div>

            <div className="result">
                <img src={enrolIcon} alt="icon"></img>
                <p>Feoda’s user-friendly design allowed for easy adoption by their Finance team: a one-hour training session wall all that was needed. Their new paperless enrolment and fee management delighted parents and staff, enabling “…seamless administration, from when the student first enters the School to the day they graduate”, according to the school’s Business Manager. In fact, 20% of the entire first semester’s tuition fees were collected within 5hrs of Feoda generating and sending the invoices. Less effort was being spent managing vendors and purchase orders, thanks to paperless workflows, and the payroll journal now took only a single hour to process. Finally, the real time view of their financial status allowed them to plan and react quickly to changing demands, such as remote learning requirements and its potential impact on fee structure.</p>
            </div>

            <div className="result">
                <img src={planIcon} alt="icon"></img>
                <p>With effort to spare and total financial visibility, the Finance team focused on the future: ongoing funding, long term planning and the construction of the new “Master Building” on their beautiful NSW campus. </p>
            </div>
        </section>
    )
}
export default Results
