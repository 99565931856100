import React from "react";
import Layout from "../components/layout";
import SectionHeader from "../components/sectionHeader";
import PageBannerCaseStudy from "../components/pageBannerCaseStudy";
import { Container, Col, Row } from "react-bootstrap";
import Results from "../components/caseStudy/Results";
import TakeOuts from "../components/caseStudy/TakeOuts";
import Testimonials from "../components/caseStudy/Testimonials";
import { Fade } from "react-reveal";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
 
import ReactMarkdown from "react-markdown";

import ideasIcon from "../assets/icons/icon-case-study.svg";

import "./../components/caseStudy.scss";

function CaseStudy() {
  const caseStudyData = useStaticQuery(graphql`
    query {
      allStrapiCaseStudyPage {
        nodes {
          CaseStudyHeader {
            HeaderDescription
            Icon {
              publicURL
            }
            Title
            TitleHighlight
            id
          }
          CaseStudyTestimonials {
            Icon {
              publicURL
            }
            TestimonialItem {
              From
              Text
              id
            }
          }
          CaseStudyTopBanner {
            publicURL
          }
          PageTitle
          casestudyarticle {
            Content
            ImagePosition
            Title
            id
            Image {
              publicURL
            }
          }
          casestudyresults {
            Heading
            HeadingIcon {
              publicURL
            }
            HeadingLine1
            HeadingLine2
            HeadingLine3
            id
            resultitem {
              Content
              Icon {
                publicURL
              }
            }
          }
          casestudytakeOuts {
            GraphImage {
              publicURL
            }
            Title
            takeoutfigure {
              Caption
              Position
              Icon {
                publicURL
              }
            }
          }
        }
      }
    }
  `);

  const pageData = caseStudyData.allStrapiCaseStudyPage.nodes[0];
  console.log(pageData);

  return (
    <Layout>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>{pageData.PageTitle}</title>
      </Helmet>

      <Container className="case-study__herobanner">
        <Fade bottom>
          <PageBannerCaseStudy data={pageData.CaseStudyTopBanner} />
        </Fade>
      </Container>

      <Container className="case-study">
        <SectionHeader
          icon={ideasIcon}
          title={pageData.CaseStudyHeader[0].Title}
          highlight={pageData.CaseStudyHeader[0].TitleHighlight}
        />

        <h5>{pageData.CaseStudyHeader[0].HeaderDescription}</h5>

        {pageData.casestudyarticle.map((article) => (
          <section className="case-study__cardtext">
            {article.ImagePosition == "Left" ? (
              <Row>
                <Col md={4}>
                  <img src={article.Image.publicURL} />
                </Col>
                <Col md={8}>
                  <h4 className="highlight">{article.Title}</h4>

                  <ReactMarkdown source={article.Content} />
                </Col>
              </Row>
            ) : (
              <Row>
                <Col md={8}>
                  <h4 className="highlight">{article.Title}</h4>

                  <ReactMarkdown source={article.Content} />
                </Col>
                <Col md={4}>
                  <img src={article.Image.publicURL} />
                </Col>
              </Row>
            )}
          </section>
        ))}

        <Results />
        <TakeOuts />
        <Testimonials />
      </Container>
    </Layout>
  );
}

export default CaseStudy;
